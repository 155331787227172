.splash-screen .splash-screen-log{
    margin-top: 20%;
}

.splash-screen .splash-screen-icon{
    margin-top: 25px;
}

.splash-screen .splash-screen-message{
    margin-top: 15px;
}