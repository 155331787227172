.App {
  text-align: center;
  font-size: 10px;
}

svg {
  vertical-align: initial !important;
}

.ant-table.ant-table-small {
  font-size: 11px;
}

.ant-btn-success {
  color: #ffffff;
  background: #009607;
  border-color: #009607;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.12);
  -webkit-box-shadow: 0 2px 0 rgba(0, 0, 0, 0.045);
  box-shadow: 0 2px 0 rgba(0, 0, 0, 0.045);
}

.ant-btn-success:hover,
.ant-btn-success:focus {
  color: #ffffff;
  background-color: #00c20a;
  border-color: #00c20a;
}

.ant-btn-success:active {
  color: #ffffff;
  background-color: #008307;
  border-color: #008307;
}

.ant-btn-warning {
  color: #000000;
  background: #ffc107;
  border-color: #ffc107;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.12);
  -webkit-box-shadow: 0 2px 0 rgba(0, 0, 0, 0.045);
  box-shadow: 0 2px 0 rgba(0, 0, 0, 0.045);
}

.ant-btn-warning:hover,
.ant-btn-warning:focus {
  color: #000000;
  background-color: #fcc318;
  border-color: #fcc318;
}

.ant-btn-warning:active {
  color: #000000;
  background-color: #e6ac00;
  border-color: #e6ac00;
}

.ant-picker {
  width: 100%;
}

.cursor-default-I {
  cursor: default !important;
}

.padding-0-I {
  padding: 0px !important;
}

.bg-warning {
  background-color: #fffb8f;
}

.ant-card.ant-card-bordered {
  height: initial !important;
}

.card-dashboard-stats {
  margin: 20px 0 0 0;
}

.card-dashboard-box {
  width: 100%;
}

.card-dashboard-box h1,
.card-dashboard-box h2,
.card-dashboard-box h4 {
  margin: 0 !important;
}

.chart-in-card {
  margin: 0 -24px -24px -24px !important;
}

.table-in-card {
  margin: 20px 0 -24px 0 !important;
}

button.link {
  border: 0px;
  box-shadow: none;
  padding: 0px;
  margin: 0px;
  background-color: transparent;
  height: unset;
  font-size: unset;
}

button.link span {
  text-decoration: underline;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

#sidebar-menu ul li a {
  padding: 8px 15px !important;
  font-size: 14px !important;
}

.background-it {background-color: #d5eaff;}
.background-fr {background-color: #d6ffd5;}
.background-es {background-color: #ffd5f4;}
.background-de {background-color: #ffffbf;}
.background-uk {background-color: #ffedd5;}
.background-eu {background-color: transparent;}

.va-top {vertical-align: top;}

.apexcharts-xaxis-label {color:#000;}