body {
    zoom: 90%
}

.page-content {
    padding: calc(#{$header-height}) 0 0 0;
}

.container-fluid {
    margin-top: 0;
    padding-left: 0;
    padding-right: 0;
}