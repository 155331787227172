.color-white-I {
   color: white !important;
}

.color-green-I {
   color: green !important;
}

.color-red-I {
   color: red !important;
}

.width-50px-I {
   width: 50px !important;
}

.width-60px-I {
   width: 60px !important;
}

.width-70px-I {
   width: 70px !important;
}

.cursor-default-I {
   cursor: default !important;
}

.cursor-pointer-I {
   cursor: pointer !important;
}

.display-flex {
   display: flex;
}

.display-flex-I {
   display: flex !important;
}

.ant-select-selector {
   cursor: pointer !important;
}

.flex-center {
   display: flex;
   align-items:center;
}

pre, code {
   max-width: 100%;
   /* white-space: normal !important; */
}

.icon-success {
   /* background-color: green; */
   color: #389e0d !important;
}
.icon-warning {
   /* background-color: orange; */
   color: #d48806 !important;
}
.icon-error {
   /* background-color: red; */
   color: #d9363e !important;
}

.tbold {
   font-weight:bold;
}

.margin-b-20px {
   margin-bottom: 20px;
}