// 
// _footer.scss
// 

.footer {
    bottom: 0;
    padding: 5px calc(#{$grid-gutter-width} / 2);
    position: fixed;
    z-index: 99;
    // right: 0;
    left: 0;
    width: $sidebar-width;
    text-align: center;
    color: $footer-color;
    height: $footer-height;
    background-color: $sidebar-dark-bg;
    border-top: 1px solid $body-bg;
    border-right: 1px solid $body-bg;
    font-size: 12px;
}
  
@media (max-width: 992px) {
    .footer {
        left: 0;
    }
}

// Enlarge menu
.vertical-collpsed {
    .footer {
        left: $sidebar-collapsed-width;
    }
}

body[data-layout="horizontal"] {
    .footer {
        left: 0 !important;
    }  
}